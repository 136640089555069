import { defineMessages } from 'react-intl';

export default defineMessages({
  select: {
    id: 'selectInput.select',
    defaultMessage: 'Select',
  },
  selectOrSearch: {
    id: 'selectInput.selectOrSearch',
    defaultMessage: 'Select or search',
  },
  patternMessage: {
    id: 'fnol.getStartedForm.patternMessage',
    defaultMessage: 'Please enter an INS in the correct format: XXXXX-XXXXX-INS or X-XXXXX-XXXXX-INS',
  },
  rcPatternMessage: {
    id: 'fnol.getStartedForm.rcPatternMessage',
    defaultMessage: 'Please enter an INS in the correct format: XXXX-XXXX-INS',
  },
  searchMessage: {
    id: 'addressInput.search',
    defaultMessage: 'Search',
  },
});
