import { useIntl } from 'react-intl';
import React, { MouseEventHandler, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { InputType } from '@whitelabel/helpers/types';
import { ReactComponent as TooltipIcon } from '@whitelabel/media/icons/small/tooltip.svg';
import { Modal, transformLink } from '@whitelabel/component-library';
import { decodedTooltip } from '@whitelabel/helpers/utils';
import { StyledLabel } from './styledInputLabel';

interface IInputLabelProps {
  type: InputType;
  invalid?: boolean;
  tooltipContent?: string;
  label: string;
  id: string;
  disabled?: boolean;
  isLabelHtml?: boolean;
}

const InputLabel = ({ type, invalid, tooltipContent, label, id, disabled, isLabelHtml = false }: IInputLabelProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();
  const toggle = () => setIsModalOpen((prev) => !prev);
  const handleTooltipClick: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        body={
          tooltipContent &&
          ReactHtmlParser(decodedTooltip(tooltipContent), {
            transform: transformLink(intl),
          })
        }
        toggle={toggle}
        centered
      />
      <StyledLabel
        $disabled={disabled}
        for={!['checkbox', 'radio'].includes(type) && id}
        className={invalid ? 'text-danger form-label' : 'form-label'}
      >
        {isLabelHtml ? ReactHtmlParser(label) : label}
        {tooltipContent && <TooltipIcon className="tooltip-icon" onClick={handleTooltipClick} />}
      </StyledLabel>
    </>
  );
};

export default InputLabel;
