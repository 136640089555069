import styled from 'styled-components';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp, marginBottom } from '@whitelabel/helpers/style/grid';
import { get } from '@whitelabel/helpers/style/get';

export const StyledContactForm = styled.div`
  margin-top: 2rem;
`;

export const StyledContactFormInModal = styled.div`
  padding: 1rem;
  ${mediaBreakpointUp('sm')`
    padding: 1.5rem;
  `}

  ${mediaBreakpointUp('lg')`
  padding: 2.5rem;
  `}

  p {
    color: ${themeColor('body')};
    ${marginBottom}
  }

  form > div {
    ${marginBottom}
  }
`;

export const StyledDivider = styled.hr`
  width: 100%;
  border-top: ${get('borderStyle')};
  background: none;
  ${marginBottom}

  ${mediaBreakpointUp('md')`    
    margin-top: 2rem;
  `}
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mediaBreakpointUp('md')`
    flex-direction: row;
    gap: 2rem;
  `}
`;
