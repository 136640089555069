import { array, string, StringSchema, number, NumberSchema } from 'yup';

export function isStringSchema(schema: unknown): schema is StringSchema {
  return schema instanceof string;
}

export function isArraySchema(schema: unknown): schema is StringSchema {
  return schema instanceof array;
}

export function isNumberSchema(schema: unknown): schema is NumberSchema {
  return schema instanceof number;
}
