import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Field, FormikHelpers, FormikValues, useField } from 'formik';
import { useSelector } from 'react-redux';
import FormikFormField from '@whitelabel/xcover-shared/components/FormikFormField';
import contactFormMsg from '@whitelabel/xcover-shared/helpers/messages/contactFormMsg';
import formikFormMsg from '@whitelabel/xcover-shared/helpers/messages/formikFormFieldMsg';
import {
  getLoggedInContactReasons,
  getNotLoggedInContactReasons,
} from '@whitelabel/xcover-shared/helpers/messages/contactReasons';
import { getUserCountry } from '@whitelabel/helpers/site';
import { RootState } from '../../../store';

interface IContactReasonFieldProps {
  referer?: string | null;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
}

const ContactReasonField = ({ setFieldValue, referer }: IContactReasonFieldProps) => {
  const user = useSelector((state: RootState) => state.user.data);
  const { formatMessage } = useIntl();
  const [{ value: reasonOneValue }] = useField('contactReasonLevelOne');

  const countryCode = getUserCountry();

  const contactReasons = useMemo(
    () =>
      user
        ? getLoggedInContactReasons({ formatMessage, countryCode, referer })
        : getNotLoggedInContactReasons(formatMessage),
    [countryCode, formatMessage, referer, user],
  );
  const contactReasonsOptions = contactReasons.map(({ label, value }) => ({
    label,
    value,
  }));

  const subOptions = useMemo(
    () => contactReasons.find(({ value }) => value === reasonOneValue)?.subOptions,
    [reasonOneValue, contactReasons],
  );

  const onChange = (newValue: string) => {
    // clean contact reason two value when contact reason one changes
    if (newValue !== reasonOneValue) setFieldValue('contactReasonLevelTwo', '', false);
  };

  return (
    <>
      <Field
        component={FormikFormField}
        id="contact-reason-level-one"
        name="contactReasonLevelOne"
        type="select"
        options={contactReasonsOptions}
        label={formatMessage(contactFormMsg.contactReasonLevelOneLabel)}
        onChange={onChange}
        isSearchable={false}
        placeholder={formatMessage(formikFormMsg.select)}
      />

      {subOptions && (
        <Field
          component={FormikFormField}
          id="contact-reason-level-two"
          name="contactReasonLevelTwo"
          type="select"
          options={subOptions}
          label={formatMessage(contactFormMsg.contactReasonLevelTwoLabel)}
          isSearchable={false}
          placeholder={formatMessage(formikFormMsg.select)}
        />
      )}
    </>
  );
};

export default ContactReasonField;
