import { createIntl, IntlShape } from 'react-intl';

// We need the language to be sent to zendesk in the format of "<lang name in english> - <lang name in user selected lang>" eg: "Spanish - Esponol"
// So to obtain lang name in english new createIntl is used.
export const getZendeskLanguage = (intl: IntlShape): string => {
  const defaultIntl = createIntl({ locale: 'en' });
  const language = `${defaultIntl.formatDisplayName(intl.locale, { type: 'language' })} - ${intl.formatDisplayName(
    intl.locale,
    {
      type: 'language',
    },
  )}`;
  return language;
};
