import styled from 'styled-components';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import theme from '@whitelabel/global-theme';

export const StyledSelectListingContainer = styled('span')`
  ${mediaBreakpointUp('md')`
    display: flex;
    justify-content: space-between;
  `}
`;

export const StyledINSCode = styled('span')`
  ${fontStyle('formValue')};
  ${mediaBreakpointUp('md')`
    margin-inline-end: 1rem;
    font-size: 1.25rem;
    white-space: nowrap;
  `}
`;

export const StyledBookingTitle = styled('span')`
  display: block;
  ${fontStyle('accordionHeader')};
  color: ${theme.grays[600]};
  ${mediaBreakpointUp('md')`
      display: flex;
  `}
`;
