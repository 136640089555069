import React from 'react';
import { useIntl } from 'react-intl';
import { Field, FormikHelpers, FormikValues } from 'formik';
import FormikFormField from '@whitelabel/xcover-shared/components/FormikFormField';
import { ZENDESK_API_URL } from '@whitelabel/xcover-shared/helpers/api';
import contactFormMsg from '@whitelabel/xcover-shared/helpers/messages/contactFormMsg';
import xcoverCommonMessages from '../../../helpers/messages/commonMsg';
import BookingsField from './BookingsField';
import ContactReasonField from './ContactReasonField';

interface IContactFormBaseFieldsProps {
  setIsSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  showContactReason?: boolean;
  contactEnquiryFieldLabel?: string;
  contactDocumentsFieldLabel?: string;
  referer?: string | null;
}

const ALLOWED_FILE_TYPES = 'JPG, JPEG, TIFF, GIF, BMP, PNG, PDF, AVI, MOV, MP4, WMV, FLV';

const ContactFormBaseFields = ({
  setIsSubmitDisabled,
  isSubmitting,
  setFieldValue,
  showContactReason = true,
  contactEnquiryFieldLabel,
  contactDocumentsFieldLabel,
  referer,
}: IContactFormBaseFieldsProps): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <Field
        id="contactFullName"
        type="text"
        name="fullName"
        label={intl.formatMessage(contactFormMsg.fullNameLabel)}
        component={FormikFormField}
      />
      <Field
        id="contactEmail"
        type="email"
        name="email"
        label={intl.formatMessage(xcoverCommonMessages.emailLabel)}
        component={FormikFormField}
      />
      <Field
        id="contactPhone"
        name="phone"
        autoComplete="tel"
        type="tel"
        label={intl.formatMessage(contactFormMsg.phoneLabel)}
        component={FormikFormField}
      />

      <BookingsField setIsSubmitDisabled={setIsSubmitDisabled} isSubmitting={isSubmitting} />
      {showContactReason && <ContactReasonField setFieldValue={setFieldValue} referer={referer} />}
      <Field
        id="contactEnquiry"
        type="textarea"
        name="enquiry"
        label={contactEnquiryFieldLabel || intl.formatMessage(contactFormMsg.enquiryLabel)}
        component={FormikFormField}
      />
      <Field
        id="contactDocuments"
        type="file"
        name="files"
        label={contactDocumentsFieldLabel || intl.formatMessage(contactFormMsg.documentsLabel)}
        component={FormikFormField}
        subText={intl.formatMessage(contactFormMsg.filesSubText, { allowedFileTypes: ALLOWED_FILE_TYPES })}
        maxFileSize="50MB"
        url={`${ZENDESK_API_URL}/uploads.json`}
        apiFieldName="file"
        acceptedFileTypes={[
          'image/jpg',
          'image/jpeg',
          'image/tiff',
          'image/gif',
          'image/bmp',
          'image/png',
          'application/pdf',
          'video/x-msvideo',
          'video/quicktime',
          'video/mp4',
          'video/x-ms-wmv',
          'video/x-flv',
          'video/avi',
        ]}
        allowedFileTypes={ALLOWED_FILE_TYPES}
        isZendesk
      />
    </>
  );
};

export default ContactFormBaseFields;
