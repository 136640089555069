import React, { useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Alert } from '@whitelabel/component-library';
import { StyledContactForm } from '@whitelabel/xcover-shared/styled/StyledContactForm';
import contactFormMsg from '@whitelabel/xcover-shared/helpers/messages/contactFormMsg';
import { IContactFormValues } from '@whitelabel/xcover-shared/helpers/types';
import { bwContactTracking } from '@whitelabel/helpers/analytics';
import { getContactFormSchema } from '../../../helpers/forms/schema';
import xcoverCommonMessages from '../../../helpers/messages/commonMsg';
import { RootState } from '../../../store';
import { createSupportTicket, useGetInitialValues } from './helpers';
import ContactFormBaseFields from './ContactFormBaseFields';

interface IContactFormProps {
  showContactReason?: boolean;
  contactEnquiryFieldLabel?: string;
  contactDocumentsFieldLabel?: string;
  slug?: string;
}
// the partner name that they originally purchased the policy from is included in the submission
const ContactForm = ({
  showContactReason = true,
  contactEnquiryFieldLabel,
  contactDocumentsFieldLabel,
  slug,
}: IContactFormProps): JSX.Element => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { search } = useLocation();
  const referer = new URLSearchParams(search).get('referer');
  const claimReference = new URLSearchParams(search).get('claimReference');
  const user = useSelector(({ user: { data } }: RootState) => data);
  const isSocialReview = slug === 'social-review';
  const isPostSettlement = referer === 'postSettlement';
  const validationSchema = getContactFormSchema(
    formatMessage,
    !!user || isSocialReview || isPostSettlement,
    showContactReason,
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleSubmit = async (values: IContactFormValues, formikHelpers: FormikHelpers<IContactFormValues>) => {
    await createSupportTicket({ intl, isLoggedIn: !!user, referer, claimReference })(values, formikHelpers);
  };

  useEffect(() => {
    if (isSocialReview) {
      bwContactTracking({
        source: slug,
        referer,
      });
    }
  }, [isSocialReview, referer, slug]);

  return (
    <StyledContactForm>
      <Row>
        <Col md="8">
          <Formik
            initialValues={useGetInitialValues({ showContactReason, referer })}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, status, setFieldValue }) => (
              <>
                {status === 'success' && <Alert color="success">{formatMessage(contactFormMsg.successMessage)}</Alert>}
                {status === 'api_error' && <Alert color="danger">{formatMessage(xcoverCommonMessages.error)}</Alert>}
                <Form>
                  <ContactFormBaseFields
                    setIsSubmitDisabled={setIsSubmitDisabled}
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    showContactReason={showContactReason}
                    contactEnquiryFieldLabel={contactEnquiryFieldLabel}
                    contactDocumentsFieldLabel={contactDocumentsFieldLabel}
                    referer={referer}
                  />
                  <Button type="submit" loading={isSubmitting} disabled={isSubmitDisabled}>
                    {formatMessage(xcoverCommonMessages.submitButtonText)}
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </StyledContactForm>
  );
};
export default ContactForm;
