import { defineMessages } from 'react-intl';

export default defineMessages({
  fnolGetStartedTitle: {
    id: 'pages.fnolGetStarted.title',
    defaultMessage: 'Make a Claim',
  },
  bookingIDLabel: {
    id: 'fnol.getStartedForm.bookingIDLabel',
    defaultMessage: 'What is the reference number you want to claim against?',
  },
  bookingIDHelpText: {
    id: 'fnol.getStartedForm.bookingIDHelpText',
    defaultMessage:
      'You can find your reference number (ending with -INS) in your confirmation email or {accountPageLink}.' +
      ' If you have any issues submitting a claim, please {contactLinkText}.',
  },
  accountPageLinkText: {
    id: 'fnol.getStartedForm.accountPageLinkText',
    defaultMessage: 'Your Protection page',
  },
  rcAccountPageLinkText: {
    id: 'fnol.getStartedForm.rcAccountPageLinkText',
    defaultMessage: 'Your RentalCover Account',
  },
  buttonLabel: {
    id: 'fnol.getStartedForm.buttonLabel',
    defaultMessage: 'Start Claim',
  },
  newClaimButtonLabel: {
    id: 'fnol.getStartedForm.newClaimButtonLabel',
    defaultMessage: 'Start A New Claim',
  },
  continueButtonLabel: {
    id: 'fnol.getStartedForm.continueButtonLabel',
    defaultMessage: 'Continue Claim',
  },
  bookingIDNotFound: {
    id: 'fnol.getStartedForm.bookingIDNotFound',
    defaultMessage:
      'Sorry, we’re unable to find the reference number in our system.  Please check the reference number' +
      ' is correct and try again. If you continue to have issues with the reference number, please contact {link}',
  },
  policyIsCancelled: {
    id: 'fnol.getStartedForm.policyIsCancelled',
    defaultMessage:
      'Your policy was cancelled on {bookingCancelledDate}, as a result it’s no longer possible to make a claim.' +
      ' If you need further support, you can check out the {helpCentre}, reply to any of our emails' +
      ' or use {link} to contact our friendly staff.',
  },
  rcPolicyIsCancelled: {
    id: 'fnol.getStartedForm.rcPolicyIsCancelled',
    defaultMessage:
      'Your policy was cancelled on {bookingCancelledDate}, as a result it’s no longer possible to make a claim.' +
      ' If you need further support, you can check out the {helpCentre}, reply to any of our emails' +
      ' or use {link} to contact our friendly staff.',
  },
  yes: {
    id: 'pages.amazonSurvey.claimAccepted.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'pages.amazonSurvey.claimAccepted.no',
    defaultMessage: 'No',
  },
  contactLinkText: {
    id: 'contactLinkText',
    defaultMessage: 'Contact us',
  },
  insPlaceholder: {
    id: 'insField.placeholder',
    defaultMessage: 'XXXXX-XXXXX-INS or X-XXXXX-XXXXX-INS',
  },
  policyWording: {
    id: 'policyWording',
    defaultMessage: 'policy wording',
  },
  fnolClaimDetailsSeoTitle: {
    id: 'pages.fnolClaimDetails.title',
    defaultMessage: 'Claim Questions',
  },
});
