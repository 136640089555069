import { defineMessages } from 'react-intl';

export default defineMessages({
  fullNameLabel: {
    id: 'contactForm.fullNameLabel',
    defaultMessage: `Full Name`,
  },
  refNumLabel: {
    id: 'contactForm.refNumLabel',
    defaultMessage: `Reference Number (optional)`,
  },
  refNumLabelHelpText: {
    id: 'contactForm.refNumLabelHelpText',
    defaultMessage: `You can find your reference number (ending with -INS) in your confirmation email or {accountPageLink}. If you have any issues submitting a claim, please contact {emailLink}.`,
  },
  accountPageLinkText: {
    id: 'contactForm.accountPageLinkText',
    defaultMessage: 'Your Protection page',
  },
  phoneLabel: {
    id: 'contactForm.phoneLabel',
    defaultMessage: 'Phone Number (optional)',
  },
  enquiryLabel: {
    id: 'contactForm.enquiryLabel',
    defaultMessage: `Your Enquiry`,
  },
  documentsLabel: {
    id: 'contactForm.documentsLabel',
    defaultMessage: `Documents related to your enquiry (optional)`,
  },
  successMessage: {
    id: 'contactForm.successMessage',
    defaultMessage: `Thank you, your enquiry has been submitted.`,
  },
  leadText: {
    id: 'contactFormInModal.leadText',
    defaultMessage: `Please fill out your details and we will contact you shortly.`,
  },
  filesSubText: {
    id: 'contactFormInModal.filesSubText',
    defaultMessage: `Allowed file type: {allowedFileTypes}`,
  },
  submitButtonText: {
    id: 'contactFormInModal.submitButtonText',
    defaultMessage: `Submit enquiry`,
  },
  contactReasonLevelOneLabel: {
    id: 'contactForm.contactReasonLevelOneLabel',
    defaultMessage: 'Help us solve your query faster by selecting an option below.',
  },
  contactReasonLevelTwoLabel: {
    id: 'contactForm.contactReasonLevelTwoLabel',
    defaultMessage: 'Choose a topic that best matches your query.',
  },
  notLoggedInContactReasonOption1: {
    id: 'contactForm.notLoggedIn.contactReasonOption1',
    defaultMessage: 'I want to know more about XCover Protection.',
  },
  notLoggedInContactReasonOption1Sub1: {
    id: 'contactForm.notLoggedIn.contactReasonOption1Sub1',
    defaultMessage: 'What’s covered by XCover Protection?',
  },
  notLoggedInContactReasonOption1Sub2: {
    id: 'contactForm.notLoggedIn.contactReasonOption1Sub2',
    defaultMessage: 'How do I buy XCover Protection?',
  },

  notLoggedInContactReasonOption2: {
    id: 'contactForm.notLoggedIn.contactReasonOption2',
    defaultMessage: 'I have a question regarding my XCover Protection documents.',
  },
  notLoggedInContactReasonOption2Sub1: {
    id: 'contactForm.notLoggedIn.contactReasonOption2Sub1',
    defaultMessage: 'I haven’t received any XCover emails.',
  },
  notLoggedInContactReasonOption2Sub2: {
    id: 'contactForm.notLoggedIn.contactReasonOption2Sub2',
    defaultMessage: 'I want to know where I can find my XCover Protection documents.',
  },

  notLoggedInContactReasonOption3: {
    id: 'contactForm.notLoggedIn.contactReasonOption3',
    defaultMessage: 'I want to know more about pricing.',
  },
  notLoggedInContactReasonOption3Sub1: {
    id: 'contactForm.notLoggedIn.contactReasonOption3Sub1',
    defaultMessage: 'Will this be a recurring payment or a once-off purchase?',
  },
  notLoggedInContactReasonOption4: {
    id: 'contactForm.notLoggedIn.contactReasonOption4',
    defaultMessage: 'I would like a quote.',
  },
  notLoggedInContactReasonOption5: {
    id: 'contactForm.notLoggedIn.contactReasonOption5',
    defaultMessage: 'I want to know more about the claims process.',
  },
  notLoggedInContactReasonOption6: {
    id: 'contactForm.notLoggedIn.contactReasonOption6',
    defaultMessage: 'I need help with a tech issue.',
  },
  notLoggedInContactReasonOption6Sub1: {
    id: 'contactForm.notLoggedIn.contactReasonOption6Sub1',
    defaultMessage: 'I haven’t received any XCover emails.',
  },
  notLoggedInContactReasonOption7: {
    id: 'contactForm.notLoggedIn.contactReasonOption7',
    defaultMessage: 'I want to become a partner',
  },
  loggedInContactReasonOption1: {
    id: 'contactForm.loggedIn.contactReasonOption1',
    defaultMessage: 'I want to know where I can find my XCover Protection documents.',
  },

  loggedInContactReasonOption2: {
    id: 'contactForm.loggedIn.contactReasonOption2',
    defaultMessage: 'I want to know more about my policy coverage.',
  },
  loggedInContactReasonOption2Sub1: {
    id: 'contactForm.loggedIn.contactReasonOption2Sub1',
    defaultMessage: 'When does my coverage start and end?',
  },
  loggedInContactReasonOption2Sub2: {
    id: 'contactForm.loggedIn.contactReasonOption2Sub2',
    defaultMessage: 'What does my XCover Protection cover?',
  },
  loggedInContactReasonOption2Sub3: {
    id: 'contactForm.loggedIn.contactReasonOption2Sub3',
    defaultMessage: 'Is my XCover Protection active or cancelled?',
  },
  loggedInContactReasonOption3: {
    id: 'contactForm.loggedIn.contactReasonOption3',
    defaultMessage: 'I want to modify my XCover Protection.',
  },
  loggedInContactReasonOption3Sub1: {
    id: 'contactForm.loggedIn.contactReasonOption3Sub1',
    defaultMessage: 'I want to modify some personal details.',
  },
  loggedInContactReasonOption3Sub2: {
    id: 'contactForm.loggedIn.contactReasonOption3Sub2',
    defaultMessage: 'I want to modify my policy dates.',
  },
  loggedInContactReasonOption3Sub3: {
    id: 'contactForm.loggedIn.contactReasonOption3Sub3',
    defaultMessage: 'I want to transfer my XCover Protection.',
  },
  loggedInContactReasonOption3Sub4: {
    id: 'contactForm.loggedIn.contactReasonOption3Sub4',
    defaultMessage: 'My XCover policies are in separate accounts so I want to merge them.',
  },
  loggedInContactReasonOption4: {
    id: 'contactForm.loggedIn.contactReasonOption4',
    defaultMessage: 'I want to cancel my XCover Protection.',
  },
  loggedInContactReasonOption5: {
    id: 'contactForm.loggedIn.contactReasonOption5',
    defaultMessage: 'I have a question about billing and refunds for my XCover Protection.',
  },
  loggedInContactReasonOption5Sub1: {
    id: 'contactForm.loggedIn.contactReasonOption5Sub1',
    defaultMessage: 'I have a charge from XCover and want to know more.',
  },
  loggedInContactReasonOption5Sub2: {
    id: 'contactForm.loggedIn.contactReasonOption5Sub2',
    defaultMessage: 'I want to request ‒ or have requested  ‒ a refund of my policy.',
  },
  loggedInContactReasonOption5Sub3: {
    id: 'contactForm.loggedIn.contactReasonOption5Sub3',
    defaultMessage: 'Is my policy a recurring payment or a once-off cost?',
  },
  loggedInContactReasonOption6: {
    id: 'contactForm.loggedIn.contactReasonOption6',
    defaultMessage: 'I need help with my claim.',
  },
  loggedInContactReasonOption6Sub1: {
    id: 'contactForm.loggedIn.contactReasonOption6Sub1',
    defaultMessage: 'I need help submitting my claim.',
  },
  loggedInContactReasonOption6Sub2: {
    id: 'contactForm.loggedIn.contactReasonOption6Sub2',
    defaultMessage: 'I want to amend or add extra information to my existing claim.',
  },
  loggedInContactReasonOption6Sub3: {
    id: 'contactForm.loggedIn.contactReasonOption6Sub3',
    defaultMessage: 'I want to check the status of my claim.',
  },
  loggedInContactReasonOption6Sub4: {
    id: 'contactForm.loggedIn.contactReasonOption6Sub4',
    defaultMessage: 'I want to know what my claim excess is.',
  },
  loggedInContactReasonOption6Sub5: {
    id: 'contactForm.loggedIn.contactReasonOption6Sub5',
    defaultMessage: 'What documents do I need to submit my claim?',
  },
  loggedInContactReasonOption6Sub6: {
    id: 'contactForm.loggedIn.contactReasonOption6Sub6',
    defaultMessage: 'I’m experiencing technical issues submitting my claim.',
  },
  loggedInContactReasonOption6Sub8: {
    id: 'contactForm.loggedIn.contactReasonOption6Sub8',
    defaultMessage: 'I want to know more about the claim process.',
  },
  loggedInContactReasonOption7: {
    id: 'contactForm.loggedIn.contactReasonOption7',
    defaultMessage: 'I need emergency medical assistance.',
  },
  loggedInContactReasonOption8: {
    id: 'contactForm.loggedIn.contactReasonOption8',
    defaultMessage: 'I need to manage my personal data.',
  },
  loggedInContactReasonOption8Sub1: {
    id: 'contactForm.loggedIn.contactReasonOption8Sub1',
    defaultMessage: 'I want my data deleted.',
  },
  loggedInContactReasonOption8Sub2: {
    id: 'contactForm.loggedIn.contactReasonOption8Sub2',
    defaultMessage: 'I want to unsubscribe.',
  },
  loggedInContactReasonOption9: {
    id: 'contactForm.loggedIn.contactReasonOption9',
    defaultMessage: 'I want to become a partner.',
  },
  loggedInContactReasonOption10: {
    id: 'contactForm.loggedIn.contactReasonOption10',
    defaultMessage: 'I have a question that is not related to my policy.',
  },
  loggedInContactReasonOption12: {
    id: 'contactForm.loggedIn.contactReasonOption12',
    defaultMessage: 'I would like a copy of my documents sent in the mail.',
  },
  contactReasonOption13: {
    id: 'contactForm.contactReasonOption13',
    defaultMessage: 'I want to make a complaint.',
  },
  other: {
    id: 'contactForm.otherReasons',
    defaultMessage: 'Other',
  },
  chatWithUs: {
    id: 'contactForm.chatWithUs',
    defaultMessage: 'Chat with us',
  },
  callUs: {
    id: 'contactForm.callUs',
    defaultMessage: 'Call us',
  },
  chatWithUsText: {
    id: 'contactForm.chatWithUs.description',
    defaultMessage: `We’re here to help! {br} Please have your XCover Reference number ready. You can find it in the subject line of the confirmation email from us or under {accountLink}. It ends with (-INS).`,
  },
  callUsTextLoggedIn: {
    id: 'contactForm.callUs.loggedIndescription',
    defaultMessage: `We’re here to help! {br}
    For enquiries related to your XCover Protection, please have your XCover Reference number ready. You can find it in the subject line of the confirmation email from us under {accountLink}.  It ends with (-INS). {br}
    For enquiries related to your {partner} purchase, please contact them.`,
  },
  callUsTextLoggedOut: {
    id: 'contactForm.callUs.loggedOutdescription',
    defaultMessage: `For enquiries related to your XCover Protection, please have your XCover Reference number ready. You can find it in the subject line of the confirmation email  from us or under {accountLink}. It ends with (-INS). {br}
    For enquiries related to purchasing XCover Protection for an Amazon item, please have the product number ready. You can find it in the product specification section of the item's listing on Amazon.`,
  },
  callUsText: {
    id: 'contactForm.callUs.callUsText',
    defaultMessage:
      'We can only assist with enquiries related to your XCover Protection. To do this, we will need your XCover Reference Number. It ends in "-INS" and you can find it in the subject line of emails from XCover.',
  },
  yourProtection: {
    id: 'contactForm.yourProtection',
    defaultMessage: 'Your Protection',
  },
  launchChatBtn: {
    id: 'contactForm.chatWithUs.button',
    defaultMessage: 'Launch chat',
  },
  callUsBtn: {
    id: 'contactForm.callUs.button',
    defaultMessage: `Call {phoneNumber}`,
  },
  formLeadText: {
    id: 'contactForm.leadText',
    defaultMessage: 'Please fill out the information below and we will be in contact with you shortly.',
  },
});
