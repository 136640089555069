import { useEffect } from 'react';
import { KW_AGENT_INFO } from '@whitelabel/xcover-shared/helpers/constants';
import { storeKWAgentInfo } from '@whitelabel/xcover-shared/helpers/hubspot';
import { IBooking } from '@whitelabel/helpers/types';

export const useSetClientStorageFromState = (bookings?: Record<string, IBooking>) => {
  useEffect(() => {
    if (bookings && !localStorage.getItem(KW_AGENT_INFO)) {
      const resultwithKWBooking = Object.values(bookings).find((booking) => booking.policyTypes?.home_warranty_cover);
      if (resultwithKWBooking) storeKWAgentInfo(resultwithKWBooking);
    }
  }, [bookings]);
};
