import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Container } from 'reactstrap';
import { GetServerSideProps } from 'next';
import { ContentBlock } from '@whitelabel/component-library';
import ContactForm from '@whitelabel/xcover-www-frontend/src/components/forms/ContactForm';
import api from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { IPage } from '@whitelabel/helpers/types';
import { PUBLIC_NAV_ITEMS } from '@whitelabel/xcover-shared/helpers/constants';
import PageMeta from '@whitelabel/xcover-shared/components/PageMeta';
import ErrorPage from '../components/ErrorPage';
import NavBar from '../components/NavBar';
import { StyledLayout } from '../styled/StyledLayout';
import { getRequestIDInit } from '../../logger';
import { setErrorResponse, setSuccessResponse } from '../helpers/serverResponse';

interface IStaticPageProps {
  page?: IPage;
}

export const StaticPage = ({ page }: IStaticPageProps): JSX.Element => {
  const {
    query: { slug },
    locale,
    replace,
  } = useRouter();
  useEffect(() => {
    const slugMap: Record<string, string> = {
      'electronics-insurance': 'electronics-protection',
      'electronics-warranty-insurance': 'electronics-protection',
      'appliances-insurance': 'appliances-protection',
      'appliances-warranty-insurance': 'appliances-protection',
      'furniture-insurance': 'furniture-protection',
      'furniture-warranty-insurance': 'furniture-protection',
      'sports-equipment-insurance': 'sports-equipment-protection',
      'sports-equipment-warranty-insurance': 'sports-equipment-protection',
      'eyewear-insurance': 'eyewear-protection',
      'eyewear-warranty-insurance': 'eyewear-protection',
      'jewelry-insurance': 'jewelry-protection',
      'travel-insurance': 'travel-protection',
      'tickets-and-events-insurance': 'tickets-and-events-protection',
      quote: 'get-quote/product',
      contact: `contact-us`,
    };
    const newSlug = slugMap[slug as string];

    if (newSlug) {
      replace(`/${locale}/${newSlug}`);
    }
  }, [slug, locale, page, replace]);

  if (!page) {
    return <ErrorPage />;
  }

  return (
    <StyledLayout navBar={<NavBar items={PUBLIC_NAV_ITEMS} size="lg" />} spacing="lg">
      <PageMeta
        title={page.title}
        seoTitle={page.meta.seoTitle}
        searchDescription={page.meta.searchDescription}
        socialTitle={page.meta.socialTitle}
        socialDescription={page.meta.socialDescription}
        socialImage={page.meta.socialImage}
        ogType={page.meta.ogType}
      />
      <ContentBlock blocks={page.body} />
      {slug === 'contact-us' && (
        <Container>
          <ContactForm />
        </Container>
      )}
    </StyledLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ params, locale, req, res }) => {
  const { slug } = params as unknown as { slug: string };
  let page;
  try {
    page = await api.get(`${getAPIHost()}/staticpages/${slug}/?language=${locale}`, false, getRequestIDInit(req));
    setSuccessResponse(res);
  } catch (err) {
    page = null;
    setErrorResponse(res, err);
  }
  return {
    props: { page },
  };
};

export default StaticPage;
