import { FormikHelpers } from 'formik';
import { IntlShape } from 'react-intl';
import { BOOKING_REFERENCE_FILED_ID } from '@whitelabel/helpers/constants';
import { getWindowSessionReplayURL } from '@whitelabel/helpers/utils';
import { useQueryParams } from '@whitelabel/helpers/hooks';
import api, { ZENDESK_API_URL } from '@whitelabel/xcover-shared/helpers/api';
import { BOOKING_ID, LANGUAGE_FILED_ID } from '@whitelabel/xcover-shared/helpers/constants';
import { bwContactTracking } from '@whitelabel/helpers/analytics';
import { getZendeskLanguage } from '@whitelabel/xcover-shared/helpers/locales';
import loggedInContactReasonsMappingData from '@whitelabel/xcover-shared/helpers/constants/loggedInContactReasonsMappingData.json';
import { IContactFormValues } from '@whitelabel/xcover-shared/helpers/types';
import { RootState } from '../../../store';
import { useAppSelector } from '../../../store/hooks';
import { useGetCustomerBookingsQuery } from '../../../store/services/xcover/endpoints/bookings';
import { useSetClientStorageFromState } from '../../../helpers/useSetClientStorageFromState';
import notLoggedInContactReasonsMappingData from './notLoggedInContactReasonsMappingData.json';

const initialValues: IContactFormValues = {
  fullName: '',
  email: '',
  refNumber: '',
  phone: '',
  enquiry: '',
  files: [],
  contactReasonLevelOne: '',
  contactReasonLevelTwo: '',
};

interface IUseGetInitialValuesArgs {
  showContactReason?: boolean;
  booking?: string;
  referer?: string | null;
}

export const useGetInitialValues = ({ showContactReason, booking, referer }: IUseGetInitialValuesArgs) => {
  const bookingID = useQueryParams().get(BOOKING_ID) || '';
  const customer = useAppSelector((state: RootState) => state.customer.data);
  if (customer) {
    const { firstName, lastName, email, phone } = customer;
    initialValues.fullName = (firstName && lastName && `${firstName} ${lastName}`) || '';
    initialValues.email = email!;
    initialValues.phone = phone!;
  }

  if (showContactReason) {
    initialValues.contactReasonLevelOne = '';
  }
  initialValues.refNumber = booking || bookingID;

  if (referer && referer === 'claim-journey') {
    initialValues.contactReasonLevelOne = '6';
    initialValues.contactReasonLevelTwo = '6-8';
  }
  return initialValues;
};

const getContactReasonCustomFields = (value: string | undefined, isLoggedIn: boolean) => {
  if (isLoggedIn) return loggedInContactReasonsMappingData.find(({ id }) => value === id)?.value || [];
  return notLoggedInContactReasonsMappingData.find(({ id }) => value === id)?.value || [];
};

const getSource = () => {
  const { pathname } = window.location;
  if (pathname.endsWith('complaints')) {
    return 'complaints';
  }
  if (pathname.endsWith('contact-us')) {
    return 'contact-us';
  }
  if (pathname.endsWith('claim-info')) {
    return 'claim-info';
  }
  if (pathname.endsWith('social-review')) {
    return 'social-review';
  }
  return 'wizard';
};

const getSubject = (isComplaintReason: boolean) => {
  const { pathname } = window.location;
  if (pathname.endsWith('complaints') || isComplaintReason) {
    return 'Xcover.com - New Complaint';
  }
  if (pathname.endsWith('claim-info')) {
    return 'Xcover.com - New claim information';
  }
  if (pathname.endsWith('amazon-contact-us')) {
    return 'Xcover.com - New amazon customer enquiry';
  }
  if (pathname.endsWith('social-review')) {
    return 'Xcover.com - Social Review Feedback';
  }
  // for contact-us page and default
  return 'Xcover.com - New customer enquiry';
};

interface ICreateSupportTicketArgs {
  intl: IntlShape;
  isLoggedIn: boolean;
  referer?: string | null;
  claimReference?: string | null;
  isInModal?: boolean;
  partnerID?: string;
  partnerName?: string;
  subsidiaryID?: string;
  subsidiaryName?: string;
  policyTypeGroupName?: string;
}

export const createSupportTicket =
  ({
    intl,
    isLoggedIn,
    referer,
    claimReference,
    isInModal = false,
    partnerID,
    partnerName,
    subsidiaryID,
    subsidiaryName,
  }: ICreateSupportTicketArgs) =>
  async (
    values: IContactFormValues,
    { setSubmitting, setStatus, resetForm }: FormikHelpers<IContactFormValues>,
  ): Promise<void> => {
    const language = getZendeskLanguage(intl);
    const { fullName, email, refNumber, enquiry, phone, files, contactReasonLevelOne, contactReasonLevelTwo } = values;

    const contactReasonCustomFields = contactReasonLevelTwo
      ? getContactReasonCustomFields(contactReasonLevelTwo, isLoggedIn)
      : getContactReasonCustomFields(contactReasonLevelOne, isLoggedIn);
    // ZD requires BCP 47 compliant language tags

    const isComplaintReason = contactReasonLevelOne === '13';

    bwContactTracking({
      source: getSource(),
      contactReason: contactReasonCustomFields,
      bookingID: refNumber,
      request: enquiry,
      referer,
      claimReference,
      partnerID,
      partnerName,
      subsidiaryID,
      subsidiaryName,
    });

    const locale = ['zh-hans', 'zh-hant'].includes(intl.locale) ? 'zh-cn' : intl.locale;

    const requestObj = {
      request: {
        requester: { name: fullName, email, locale },
        subject: getSubject(isComplaintReason),
        comment: {
          body: `
          Reference Number: ${refNumber || `N/A`}.
          Enquiry Message: ${enquiry}
          User Email: ${email}
          Phone Number: ${phone || `N/A`}
          BW Session: ${getWindowSessionReplayURL() || 'N/A'}
        `,
          uploads: files,
        },
        custom_fields: [
          { id: BOOKING_REFERENCE_FILED_ID, value: refNumber || '' },
          { id: LANGUAGE_FILED_ID, value: language || '' },
          ...contactReasonCustomFields,
        ],
      },
    };

    try {
      await api.post(`${ZENDESK_API_URL}/requests`, false, {
        body: JSON.stringify(requestObj),
      });
      resetForm({ values: initialValues });
      setStatus('success');
    } catch (e) {
      setStatus('api_error');
    } finally {
      setSubmitting(false);
      if (!isInModal) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  };

export const useGetCustomerBookings = () => {
  const { locale } = useAppSelector((state: RootState) => state.intl);
  const user = useAppSelector((state: RootState) => state.user.data);
  const userId = useAppSelector((state: RootState) => state.customer.data?.id) ?? '';

  const { data, isFetching } = useGetCustomerBookingsQuery({ id: userId, locale }, { skip: !user });
  useSetClientStorageFromState(data?.bookings);

  const insArray = data?.all || [];
  const showSelect = insArray.length < 10 && !!user;

  return [insArray, showSelect, data?.bookings, isFetching, user] as const;
};
