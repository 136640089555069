import { string, object, number, date, boolean } from 'yup';
import { IntlShape } from 'react-intl';
import { IAddressFieldInfo } from '../types';
import { FORM_INPUT_TYPES } from '../constants';
import { checkDynamicName } from '../utils';
import { messages } from './messages';

export function allowEmptyString(value: unknown, originalValue: unknown) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
}

export const getQuoteFormSchemaType = (type: any, intl: IntlShape) => {
  switch (type) {
    case 'number':
      return number().transform(allowEmptyString);
    case 'datepicker':
      return date().transform(allowEmptyString);
    case 'country_select':
      return string();
    case FORM_INPUT_TYPES.ADDRESS:
      return object({
        region: string().required(intl.formatMessage(messages.regionRequired)),
        postcode: string().required(intl.formatMessage(messages.postcodeRequired)),
      });
    default:
      return string().trim();
  }
};

export const getQuestionSchema = (
  { name, input, validation: { min, max, required, regex } }: any,
  intl: IntlShape,
  setGlobalError: any,
  validCountry = 'AU',
) => {
  const locationErrorMessage = intl.formatMessage(messages.isSupportedLocation);
  let schema = getQuoteFormSchemaType(input, intl).label(name);
  if (required) {
    schema = schema.required();
  }
  if (name === 'post_code') {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 3.
    schema = schema.test('postcode', locationErrorMessage, (value: any) => {
      if (value !== undefined) {
        if (!value.match(regex) || (min && value.length < min) || (max && value.length > max)) {
          setGlobalError(locationErrorMessage);
          return false;
        }
      }
      setGlobalError(null);
      return true;
    });
  } else if (name === 'country') {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 3.
    schema = schema.test('country', locationErrorMessage, (value: any) => {
      if (value !== validCountry) {
        setGlobalError(locationErrorMessage);
        return false;
      }
      setGlobalError(null);
      return true;
    });
  } else if (input === FORM_INPUT_TYPES.ADDRESS) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 3.
    schema = schema.test(name, ' ', (value: any) => {
      if (value.country !== 'AU') {
        setGlobalError(locationErrorMessage);
        return false;
      }
      setGlobalError(null);
      return true;
    });
  } else {
    if (min) schema = (schema as any).min(min);
    if (max) schema = (schema as any).max(max);
  }
  return schema;
};

export const getPasswordSchema = (intl: IntlShape, containsUpperAlpha?: boolean) => {
  const min = 8;
  const max = 99;
  const schema = string()
    .required()
    .min(min)
    .max(max)
    .matches(/[\d+]+/, intl.formatMessage(messages.containsNumber))
    .matches(/[a-zA-Z]+/, intl.formatMessage(messages.containsAlpha))
    .matches(/^[\S]+.*[\S]+$/, intl.formatMessage(messages.noSpaceAtStartAndEnd));

  if (containsUpperAlpha) return schema.matches(/[A-Z]+/, intl.formatMessage(messages.containsUpperAlpha));
  return schema;
};

export const getHexSchema = (intl: IntlShape) =>
  string()
    .required(intl.formatMessage(messages.required))
    .matches(/^#[0-9a-fA-F]{6}/, intl.formatMessage(messages.hexValue));

export const getEmailSchema = (required = true) => {
  let schema = string().trim();
  if (required) schema = schema.required();
  return schema.lowercase().email();
};

export const fullNameSchema = (intl: IntlShape) => string().trim().required(intl.formatMessage(messages.required));

export const isValidPhoneNumber = async (value: string): Promise<boolean> => {
  try {
    const phoneNumberUtil = (await import('google-libphonenumber')).PhoneNumberUtil.getInstance();
    return phoneNumberUtil.isValidNumber(phoneNumberUtil.parse(value));
  } catch {
    return false;
  }
};

export const getPhoneSchema = (intl: IntlShape, required = true) => {
  let schema = string().trim();
  if (required) schema = schema.required();
  return schema.test('phone', intl.formatMessage(messages.isPhone), (value) => {
    if (value) {
      return isValidPhoneNumber(value);
    }
    return true;
  });
};

export const getTermsConsentSchema = (intl: IntlShape) => boolean().isTrue(intl.formatMessage(messages.required));

export const getAddressInputSchema = (intl: IntlShape, isAddressInputManual: boolean) =>
  object({
    address1: isAddressInputManual ? string().required(intl.formatMessage(messages.address1Required)) : string(),
    address2: string(),
    city: string().nullable(),
    region: string().nullable(),
    postcode: string(),
    country: string().required(
      intl.formatMessage(isAddressInputManual ? messages.countryRequired : messages.invalidFreeAddress),
    ),
  }).required();

export const getFNOLAddressInputSchema = (
  intl: IntlShape,
  nameList?: IAddressFieldInfo<string>,
  requiredList?: IAddressFieldInfo<boolean>,
) =>
  object({
    [checkDynamicName('address1', nameList)]: requiredList?.address1
      ? string().required(intl.formatMessage(messages.required))
      : string().nullable(),
    [checkDynamicName('address2', nameList)]: requiredList?.address2
      ? string().required(intl.formatMessage(messages.required))
      : string().nullable(),
    [checkDynamicName('city', nameList)]: requiredList?.city
      ? string().required(intl.formatMessage(messages.required))
      : string().nullable(),
    [checkDynamicName('region', nameList)]: requiredList?.region
      ? string().required(intl.formatMessage(messages.required))
      : string().nullable(),
    [checkDynamicName('postcode', nameList)]: requiredList?.postcode
      ? string().required(intl.formatMessage(messages.required))
      : string().nullable(),
    [checkDynamicName('country', nameList)]: requiredList?.country
      ? string().required(intl.formatMessage(messages.required))
      : string().nullable(),
  }).required();

export type AddressInputSchema = ReturnType<typeof getAddressInputSchema>;
