import { IntlShape } from 'react-intl';
import messages from './contactFormMsg';

interface IGetLoggedInContactReasonsArgs {
  formatMessage: IntlShape['formatMessage'];
  countryCode?: string;
  referer?: string | null;
}

export const getLoggedInContactReasons = ({ formatMessage, countryCode, referer }: IGetLoggedInContactReasonsArgs) => [
  {
    label: formatMessage(messages.loggedInContactReasonOption1),
    value: '1',
  },
  ...(countryCode === 'GB'
    ? [
        {
          label: formatMessage(messages.loggedInContactReasonOption12),
          value: '12',
        },
      ]
    : []),
  {
    label: formatMessage(messages.loggedInContactReasonOption2),
    value: '2',
    subOptions: [
      {
        label: formatMessage(messages.loggedInContactReasonOption2Sub1),
        value: '2-1',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption2Sub2),
        value: '2-2',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption2Sub3),
        value: '2-3',
      },
      { label: formatMessage(messages.other), value: '2-4' },
    ],
  },
  {
    label: formatMessage(messages.loggedInContactReasonOption3),
    value: '3',
    subOptions: [
      {
        label: formatMessage(messages.loggedInContactReasonOption3Sub1),
        value: '3-1',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption3Sub2),
        value: '3-2',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption3Sub3),
        value: '3-3',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption3Sub4),
        value: '3-4',
      },
      { label: formatMessage(messages.other), value: '3-5' },
    ],
  },
  {
    label: formatMessage(messages.loggedInContactReasonOption4),
    value: '4',
  },
  {
    label: formatMessage(messages.loggedInContactReasonOption5),
    value: '5',
    subOptions: [
      {
        label: formatMessage(messages.loggedInContactReasonOption5Sub1),
        value: '5-1',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption5Sub2),
        value: '5-2',
      },

      {
        label: formatMessage(messages.loggedInContactReasonOption5Sub3),
        value: '5-3',
      },
      { label: formatMessage(messages.other), value: '5-4' },
    ],
  },
  {
    label: formatMessage(messages.loggedInContactReasonOption6),
    value: '6',
    subOptions: [
      ...(referer === 'claim-journey'
        ? [
            {
              label: formatMessage(messages.loggedInContactReasonOption6Sub8),
              value: '6-8',
            },
          ]
        : []),
      {
        label: formatMessage(messages.loggedInContactReasonOption6Sub1),
        value: '6-1',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption6Sub2),
        value: '6-2',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption6Sub3),
        value: '6-3',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption6Sub4),
        value: '6-4',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption6Sub5),
        value: '6-5',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption6Sub6),
        value: '6-6',
      },
      { label: formatMessage(messages.other), value: '6-7' },
    ],
  },
  {
    label: formatMessage(messages.contactReasonOption13),
    value: '13',
  },
  {
    label: formatMessage(messages.loggedInContactReasonOption7),
    value: '7',
  },
  {
    label: formatMessage(messages.loggedInContactReasonOption8),
    value: '8',
    subOptions: [
      {
        label: formatMessage(messages.loggedInContactReasonOption8Sub1),
        value: '8-1',
      },
      {
        label: formatMessage(messages.loggedInContactReasonOption8Sub2),
        value: '8-2',
      },
    ],
  },
  {
    label: formatMessage(messages.loggedInContactReasonOption9),
    value: '9',
  },
  {
    label: formatMessage(messages.loggedInContactReasonOption10),
    value: '10',
  },
  {
    label: formatMessage(messages.other),
    value: '11',
  },
];

export const getNotLoggedInContactReasons = (formatMessage: IntlShape['formatMessage']) => [
  {
    label: formatMessage(messages.notLoggedInContactReasonOption1),
    value: '1',
    subOptions: [
      {
        label: formatMessage(messages.notLoggedInContactReasonOption1Sub1),
        value: '1-1',
      },

      {
        label: formatMessage(messages.notLoggedInContactReasonOption1Sub2),
        value: '1-2',
      },
      { label: formatMessage(messages.other), value: '1-3' },
    ],
  },

  {
    label: formatMessage(messages.notLoggedInContactReasonOption2),
    value: '2',
    subOptions: [
      {
        label: formatMessage(messages.notLoggedInContactReasonOption2Sub1),
        value: '2-1',
      },
      {
        label: formatMessage(messages.notLoggedInContactReasonOption2Sub2),
        value: '2-2',
      },
      { label: formatMessage(messages.other), value: '2-3' },
    ],
  },

  {
    label: formatMessage(messages.notLoggedInContactReasonOption3),
    value: '3',
    subOptions: [
      {
        label: formatMessage(messages.notLoggedInContactReasonOption3Sub1),
        value: '3-1',
      },
      { label: formatMessage(messages.other), value: '3-2' },
    ],
  },

  { label: formatMessage(messages.notLoggedInContactReasonOption4), value: '4' },
  {
    label: formatMessage(messages.notLoggedInContactReasonOption5),
    value: '5',
  },
  {
    label: formatMessage(messages.contactReasonOption13),
    value: '13',
  },
  {
    label: formatMessage(messages.notLoggedInContactReasonOption6),
    value: '6',
    subOptions: [
      {
        label: formatMessage(messages.notLoggedInContactReasonOption6Sub1),
        value: '6-1',
      },
      { label: formatMessage(messages.other), value: '6-2' },
    ],
  },

  { label: formatMessage(messages.notLoggedInContactReasonOption7), value: '7' },
  { label: formatMessage(messages.other), value: '8' },
];
