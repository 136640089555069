import React from 'react';
import { get } from 'lodash/fp';
import { useIntl } from 'react-intl';
import { Label } from 'reactstrap';
import { FieldProps } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import { extractCity, checkDynamicName } from '@whitelabel/helpers/utils';
import { IAddressInputFields, ILocation, IAddressFieldInfo } from '@whitelabel/helpers/types';
import commonMsg from '@whitelabel/helpers/messages/commonMsg';
import ManualAddressInput from '@whitelabel/component-library/src/components/ManualAddressInput';
import FieldFeedback from '@whitelabel/component-library/src/components/FieldFeedback';
import { StyledGeoSuggest } from '@whitelabel/component-library/src/styled/StyledGeoSuggest';
import { StyledDescription } from '@whitelabel/component-library/src/styled/StyledFormText';
import {
  extractAddress,
  getDynamicFieldValue,
  getDynamicAddress,
} from '@whitelabel/component-library/src/helpers/transform';

export interface IFNOLAddressInputProps {
  className?: string;
  name: string;
  value?: IAddressInputFields;
  form: FieldProps['form'];
  setGlobalError?: (...args: any[]) => void;
  disabled?: boolean;
  inputSize?: 'base' | 'large';
  invalid?: boolean;
  nameList?: IAddressFieldInfo<string>;
  labelList?: IAddressFieldInfo<string | undefined>;
  descriptionList?: IAddressFieldInfo<string | undefined>;
  placeholderList?: IAddressFieldInfo<string | undefined>;
  'aria-errormessage'?: string;
}

const FNOLAddressInput = ({
  className,
  name,
  value,
  form: { errors, setFieldTouched, setFieldValue, setFieldError },
  setGlobalError,
  disabled = false,
  inputSize = 'base',
  invalid,
  nameList,
  labelList,
  descriptionList,
  placeholderList,
  'aria-errormessage': ariaErrorMessage,
}: IFNOLAddressInputProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const address1Field = checkDynamicName('address1', nameList);

  function onChange(text: string) {
    setFieldError(name, '');
    setFieldValue(name, { ...value, [address1Field]: text }, false);
    setGlobalError?.(null);
  }

  const onBlur = () => {
    setFieldTouched(name, true);
  };

  function onSuggestSelect(suggest?: ILocation) {
    if (!suggest) {
      // clean up the address 1 field
      setFieldValue(name, { ...value, [address1Field]: '' });
      return;
    }
    setGlobalError?.(null);

    const address = extractAddress(suggest?.gmaps?.address_components);

    address.city = extractCity(suggest?.gmaps?.address_components, suggest?.description) ?? '';

    address.address1 = suggest?.mainText ?? suggest.label;

    if (!address.region) {
      address.region = '';
    }

    if (!address.postcode) {
      address.postcode = '';
    }

    const dynamicAddress = getDynamicAddress(address, nameList);

    setFieldValue(name, { ...value, ...dynamicAddress });
  }

  function skipSuggest(suggest: google.maps.places.AutocompletePrediction) {
    return !(
      suggest.types.includes('street_address') ||
      suggest.types.includes('premise') ||
      suggest.types.includes('subpremise') ||
      suggest.types.includes('route')
    );
  }

  return (
    <div className={className}>
      <Label
        className={invalid && !!get(`${name}.${address1Field}`, errors) ? 'text-danger form-label' : 'form-label'}
      >
        {labelList?.address1 ?? formatMessage(commonMsg.addressLine1)}
      </Label>
      {descriptionList?.address1 && <StyledDescription>{ReactHtmlParser(descriptionList.address1)}</StyledDescription>}
      <StyledGeoSuggest
        name={name}
        initialValue={getDynamicFieldValue('address1', nameList, value)}
        placeDetailFields={['address_components', 'formatted_address']}
        placeholder={placeholderList?.address1}
        onChange={onChange}
        onBlur={onBlur}
        onSuggestSelect={onSuggestSelect}
        disableManualInput
        autoComplete="off"
        skipSuggest={skipSuggest}
        disabled={disabled}
        inputSize={inputSize}
        country={getDynamicFieldValue('country', nameList, value)}
        enableFNOLUserInput
        invalid={invalid && !!get(`${name}.${address1Field}`, errors)}
        aria-invalid={invalid ? 'true' : undefined}
        aria-errormessage={invalid ? ariaErrorMessage : undefined}
      />
      {invalid && <FieldFeedback error={get(`${name}.${address1Field}`, errors) as string} />}
      <ManualAddressInput
        className={className}
        name={name}
        invalid={invalid}
        errors={errors}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={value}
        hideAddressLine1
        hideAddressLine2={!nameList?.address2}
        nameList={nameList}
        labelList={labelList}
        descriptionList={descriptionList}
        placeholderList={placeholderList}
      />
    </div>
  );
};

export default FNOLAddressInput;
