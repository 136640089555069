import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Field, useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { IBooking, ReactStateSetter } from '@whitelabel/helpers/types';
import { BOOKING_ID } from '@whitelabel/xcover-shared/helpers/constants';
import sharedMessages from '@whitelabel/xcover-shared/helpers/messages/commonMsg';
import FormikFormField from '@whitelabel/xcover-shared/components/FormikFormField';
import Loading from '@whitelabel/component-library/src/components/Loading';
import {
  StyledSelectListingContainer,
  StyledINSCode,
  StyledBookingTitle,
} from '@whitelabel/xcover-shared/styled/StyledBookingsField';
import contactFormMsg from '@whitelabel/xcover-shared/helpers/messages/contactFormMsg';
import { IContactFormValues } from '@whitelabel/xcover-shared/helpers/types';
import { useGetCustomerBookings } from '../helpers';

interface IBookingsFieldProps {
  setIsSubmitDisabled: ReactStateSetter<boolean>;
  isSubmitting: boolean;
}
const fieldName = 'refNumber';
const BookingsField = ({ setIsSubmitDisabled, isSubmitting }: IBookingsFieldProps): JSX.Element => {
  const { locale, formatMessage } = useIntl();
  const [insArray, showSelect, bookings, bookingsLoading, user] = useGetCustomerBookings();
  const insPrefilled = useRef(false);
  const { values, setFieldValue } = useFormikContext();
  const fieldValue = (values as IContactFormValues)?.[fieldName];

  const emailLink = (
    <a href="mailto:support@xcover.com" target="_blank" rel="noopener noreferrer">
      support@xcover.com
    </a>
  );

  const clearINSInputError = (value: string) => {
    if (value === '') {
      setIsSubmitDisabled(false);
    }
  };

  const renderSelectLabel = (insCode: string, bookingsContent?: Record<string, IBooking>) => (
    <StyledSelectListingContainer>
      <StyledINSCode>{insCode}</StyledINSCode>
      <StyledBookingTitle>{bookingsContent?.[insCode]?.bookingTitle}</StyledBookingTitle>
    </StyledSelectListingContainer>
  );

  const options = insArray.map((insCode) => ({
    value: insCode,
    label: renderSelectLabel(insCode, bookings),
    selectLabel: insCode,
  }));

  useEffect(() => {
    let isCurrent = true;
    const isLoggedIn = !!user;
    if (isCurrent && !fieldValue && !insPrefilled.current) {
      if (!isLoggedIn) {
        const storedINS = localStorage.getItem(BOOKING_ID);
        if (storedINS) {
          setFieldValue(fieldName, storedINS);
          insPrefilled.current = true;
        }
      } else if (options.length === 1 && options[0].value) {
        // Set field value if there is only one option
        setFieldValue(fieldName, options[0].value);
        insPrefilled.current = true;
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [setFieldValue, options, fieldValue, user]);

  if (bookingsLoading) {
    return <Loading />;
  }

  return (
    <Field
      component={FormikFormField}
      id="contactReferenceNumber"
      name={fieldName}
      type={showSelect ? 'select' : 'INSInput'}
      options={options}
      label={formatMessage(contactFormMsg.refNumLabel)}
      placeholder={!showSelect && formatMessage(sharedMessages.insPlaceholder)}
      setIsStartClaimDisabled={setIsSubmitDisabled}
      onChange={clearINSInputError}
      helpText={formatMessage(contactFormMsg.refNumLabelHelpText, {
        accountPageLink: <Link to={`/${locale}/account`}>{formatMessage(contactFormMsg.accountPageLinkText)}</Link>,
        emailLink,
      })}
      {...(showSelect && { isDisabled: isSubmitting })}
    />
  );
};

export default BookingsField;
