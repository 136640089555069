import React, { useMemo } from 'react';
import { Label, Input } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { FieldProps } from 'formik';
import 'react-phone-number-input/style.css';
import { registerPlugin } from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import MaskedInput from 'react-text-mask';
import { useIntl } from 'react-intl';
import { get } from 'lodash/fp';
import { InputType, IRequirement, ISelectOption, ReactStateSetter } from '@whitelabel/helpers/types';
import PhoneInput from '@whitelabel/component-library/src/components/PhoneInput';
import FileInput from '@whitelabel/component-library/src/components/FileInput';
import PriceInput, { IPriceInputProps } from '@whitelabel/component-library/src/components/PriceInput';
import CurrencyInput from '@whitelabel/component-library/src/components/CurrencyInput';
import CurrencyFormattedInput, {
  ICurrencyFormattedInputProps,
} from '@whitelabel/component-library/src/components/CurrencyFormattedInput';
import PercentageInput from '@whitelabel/component-library/src/components/PercentageInput';
import CardOptions, { ICardOptionsProps } from '@whitelabel/component-library/src/components/CardOptions';
import FieldFeedback from '@whitelabel/component-library/src/components/FieldFeedback';
import PasswordInput from '@whitelabel/component-library/src/components/PasswordInput';
import FieldRequirements from '@whitelabel/component-library/src/components/FieldRequirements';
import CurrencyAmountInput from '@whitelabel/component-library/src/components/CurrencyAmountInput';
import AddressInput from '@whitelabel/component-library/src/components/AddressInput';
import DOBInput from '@whitelabel/component-library/src/components/DOBInput';
import StyledCustomInput from '@whitelabel/component-library/src/styled/StyledCustomInput';
import SelectInput from '@whitelabel/component-library/src/components/SelectInput';
import SingleDatePickerInput from '@whitelabel/component-library/src/components/SingleDatePickerInput';
import QuantityInput from '@whitelabel/component-library/src/components/QuantityInput';
import NumericInput from '@whitelabel/component-library/src/components/NumericInput';
import { site } from '@whitelabel/helpers/site';
import { sortedCurrencies } from '@whitelabel/helpers/currencies';
import { countriesToSelectOptions } from '@whitelabel/helpers/forms/transform';
import { lower } from '@whitelabel/helpers/forms/normalize';
import { getAriaDescribedBy, getDescriptionID, getHelpTextID } from '@whitelabel/helpers/utils';
import { StyledInput } from '../../styled/StyledInput';
import { REGEX_INS, REGEX_RC_INS } from '../../helpers/constants';
import FNOLAddressInput from '../FNOLAddressInput';
import messages from '../../helpers/messages/formikFormFieldMsg';
import { StyledFormText, StyledFormGroup, StyledDescription } from './styled';
import InputLabel from './InputLabel';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export type FormikFormFieldProps = {
  field: FieldProps['field'];
  form: FieldProps['form'];
  id: string;
  label?: string;
  type: InputType;
  isLabelHtml?: boolean;
  options?: ISelectOption[];
  helpText?: React.ReactNode;
  description?: React.ReactNode;
  subText?: string;
  requirements?: IRequirement[];
  formGroupProps?: {
    [key: string]: any;
  };
  className?: string;
  content?: string;
  showInitialErrors?: boolean;
  setIsStartClaimDisabled: ReactStateSetter<boolean>;
  tooltipContent?: string;
  icon?: React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
  min?: number;
  max?: number;
  isXCoverClaimsPortal?: boolean;
  /**
   * component props will be passed through
   */
  [key: string]: unknown;
} & React.InputHTMLAttributes<HTMLInputElement>;

function FormikFormField({
  field,
  form,
  id,
  label,
  isLabelHtml = false,
  type,
  options,
  helpText,
  description,
  subText,
  requirements,
  formGroupProps,
  className,
  content,
  showInitialErrors,
  setIsStartClaimDisabled,
  tooltipContent,
  isXCoverClaimsPortal = true,
  ...initialProps
}: FormikFormFieldProps): JSX.Element {
  const intl = useIntl();
  const { touched, errors: formErrors, initialErrors, setFieldTouched, setFieldValue, setFieldError } = form;

  const { invalid, error } = useMemo(() => {
    const initialError = showInitialErrors ? get(field.name, initialErrors) : '';
    const isTouched = get(field.name, touched);
    const fieldError = get(field.name, formErrors);
    const isInValid = (isTouched && !!fieldError) || !!initialError;
    return { invalid: isInValid, error: fieldError || initialError };
  }, [field.name, formErrors, initialErrors, showInitialErrors, touched]);

  const errorId = useMemo(
    () => (invalid ? `error-${(Math.random() + 1).toString(36).substring(7)}` : undefined),
    [invalid],
  );
  const props = {
    'aria-errormessage': errorId,
    ...initialProps,
  };

  const { disabled } = props;

  const ariaDescribedBy = getAriaDescribedBy(field.name, helpText, description, requirements);

  if (ariaDescribedBy) {
    props['aria-describedby'] = ariaDescribedBy;
  }

  const getSelectProps = (customPlaceholder?: string, customAutoComplete?: string, customOptions?: any[]) => {
    const { onChange, placeholder, autoComplete, ...otherProps } = props;
    return {
      input: {
        ...field,
        onChange: async (value: ISelectOption | string) => {
          if (onChange) onChange(value as React.ChangeEvent<HTMLInputElement>);
          await setFieldValue(field.name, value);
          setFieldTouched(field.name, true);
        },
        onBlur: () => setFieldTouched(field.name, true),
      },
      // inputId is used for component imported from 'react-select'
      inputId: id,
      id,
      options: customOptions ?? options,
      autoComplete: customAutoComplete ?? autoComplete,
      invalid,
      placeholder: customPlaceholder ?? placeholder,
      ...otherProps,
    };
  };

  const handleINSInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;
    await setFieldValue(
      field.name,
      event.target.value
        .trim()
        .replace(/[&/\\#,+()!@$~%^=_`.'":;[\]*?|<>{}]/g, '')
        .toUpperCase(),
    );
    setFieldTouched(field.name, true);
    setIsStartClaimDisabled(true);
    if (
      event.target.value &&
      (isXCoverClaimsPortal ? !REGEX_INS.test(event.target.value) : !REGEX_RC_INS.test(event.target.value))
    ) {
      setFieldError(
        field.name,
        intl.formatMessage(isXCoverClaimsPortal ? messages.patternMessage : messages.rcPatternMessage),
      );
    } else {
      setIsStartClaimDisabled(false);
    }
    if (onChange) {
      onChange(event.target.value as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const radioOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transformRadioValue = (radioValue: string): string | boolean => {
      if (radioValue === 'true') {
        return true;
      }
      if (radioValue === 'false') {
        return false;
      }
      return radioValue;
    };
    setFieldValue(field.name, transformRadioValue(event.target.value));
  };

  const renderInput = () => {
    const { placeholder, icon } = props;
    switch (type) {
      case 'select':
        return (
          <SelectInput
            icon={icon}
            {...getSelectProps(
              placeholder === intl.formatMessage(messages.select)
                ? intl.formatMessage(messages.select)
                : intl.formatMessage(messages.selectOrSearch),
            )}
          />
        );

      case 'country':
        return (
          <SelectInput
            {...getSelectProps(
              intl.formatMessage(messages.select),
              'country',
              countriesToSelectOptions(site.countries, intl.locale),
            )}
          />
        );

      case 'currency':
        return (
          <CurrencyInput
            {...getSelectProps(intl.formatMessage(messages.selectOrSearch), 'currency', sortedCurrencies)}
          />
        );

      case 'file':
        return <FileInput id={id} name={field.name} subText={subText} form={form} {...props} />;

      case 'boolean':
        return (
          <StyledCustomInput type="checkbox" $rtl={intl.bidi} {...props}>
            <Input {...field} id={id} type="checkbox" label={label} invalid={invalid} {...props} />
            <Label check for={id}>
              {label}
            </Label>
          </StyledCustomInput>
        );

      case 'radio':
        return (
          <div className={className}>
            {options &&
              options.map(({ value, label: optionLabel, id: optionID, ...optionProps }) => {
                const fieldID = optionID ?? id;
                return (
                  <StyledCustomInput key={`${id}-${value}`} type={type} $rtl={intl.bidi} {...props}>
                    <Input
                      {...field}
                      id={`${fieldID}-${value}`}
                      type={type}
                      value={value}
                      invalid={invalid}
                      checked={value === get(field.name, form.values)}
                      onChange={radioOnChangeHandler}
                      {...props}
                      {...optionProps}
                    />
                    <Label check for={`${fieldID}-${value}`}>
                      {optionLabel}
                    </Label>
                  </StyledCustomInput>
                );
              })}
          </div>
        );

      case 'checkbox':
        return (
          <div>
            {options &&
              options.map(({ value, label: optionLabel, ...optionProps }: any) => (
                <StyledCustomInput key={`${id}-${value}`} $rtl={intl.bidi} type={type} {...props}>
                  <Input
                    {...field}
                    id={`${id}-${value}`}
                    type={type}
                    value={value}
                    invalid={invalid}
                    checked={field.value?.includes(value)}
                    onChange={(event: any) => {
                      const set = new Set(get(field.name, form.values));

                      if (event.target.checked) {
                        set.add(value);
                      } else {
                        set.delete(value);
                      }
                      setFieldValue(field.name, Array.from(set));
                    }}
                    {...props}
                    {...optionProps}
                  />
                  <Label check for={`${id}-${value}`}>
                    {optionLabel}
                  </Label>
                </StyledCustomInput>
              ))}
          </div>
        );

      case 'email':
        return (
          <StyledInput
            {...field}
            onChange={(event: any) => {
              setFieldValue(field.name, lower(event.target.value));
            }}
            id={id}
            type={type}
            invalid={invalid}
            {...props}
          />
        );

      case 'password':
        return <PasswordInput {...field} id={id} type={type} invalid={invalid} {...props} />;

      case 'date':
      case 'datetime':
        return <SingleDatePickerInput {...field} id={id} form={form} invalid={invalid} intl={intl} {...props} />;

      case 'dob':
        return <DOBInput {...field} id={id} form={form} invalid={invalid} {...props} />;

      case 'dateMasked':
      case 'datetimeMasked': {
        let mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-1]/, /[0-9]/, '-', /[0-3]/, /[0-9]/];
        if (type === 'datetimeMasked') {
          mask = mask.concat([' ', /[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]);
        }
        return (
          <StyledInput {...field} tag={MaskedInput} id={id} type="text" invalid={invalid} mask={mask} {...props} />
        );
      }

      case 'card': {
        const cardOptionsProps = {
          ...field,
          form,
          options,
          ...props,
        } as unknown as ICardOptionsProps;
        return <CardOptions {...cardOptionsProps} />;
      }

      case 'image_select': {
        return <CardOptions {...field} form={form} options={options} {...props} />;
      }

      case 'tel':
        return <PhoneInput {...field} id={id} type={type} form={form} {...props} />;

      case 'content':
        return content ? <div className="form-content-component">{ReactHtmlParser(content)}</div> : null;

      case 'address': {
        return (
          <AddressInput
            invalid={invalid}
            label={label}
            {...field}
            form={form}
            placeholder={intl.formatMessage(messages.searchMessage)}
            {...props}
          />
        );
      }

      case 'FNOLAddress':
        return <FNOLAddressInput invalid={invalid} {...field} form={form} {...props} />;

      case 'INSInput': {
        const { onChange, ...otherProps } = props;
        return (
          <StyledInput
            {...field}
            onChange={handleINSInputChange}
            onBlur={() => {
              if (
                field.value &&
                (isXCoverClaimsPortal ? !REGEX_INS.test(field.value) : !REGEX_RC_INS.test(field.value))
              ) {
                setFieldError(
                  field.name,
                  intl.formatMessage(isXCoverClaimsPortal ? messages.patternMessage : messages.rcPatternMessage),
                );
              }
            }}
            invalid={invalid}
            maxLength="17"
            {...otherProps}
          />
        );
      }

      case 'price': {
        const priceInputProps = {
          ...field,
          id,
          type,
          invalid,
          ...props,
        } as unknown as IPriceInputProps;
        return <PriceInput {...priceInputProps} />;
      }

      case 'currencyAmount':
        return <CurrencyAmountInput {...field} id={id} invalid={invalid} {...props} />;

      case 'currencyFormatted': {
        const currencyFormattedInputProps = {
          ...field,
          id,
          invalid,
          ...props,
        } as unknown as ICurrencyFormattedInputProps;
        return <CurrencyFormattedInput {...currencyFormattedInputProps} />;
      }

      case 'percentage':
        return <PercentageInput {...field} id={id} invalid={invalid} {...props} />;

      case 'quantityController': {
        return <QuantityInput {...field} name={field.name} {...props} setFieldValue={setFieldValue} />;
      }

      case 'number': {
        return <NumericInput {...field} id={id} form={form} invalid={invalid} {...props} />;
      }

      default:
        return <StyledInput {...field} id={id} type={type} invalid={invalid} {...props} />;
    }
  };
  return (
    <StyledFormGroup {...formGroupProps} className={`${className} form-group`} $rtl={intl.bidi}>
      {type !== 'boolean' &&
        type !== 'address' &&
        type !== 'FNOLAddress' &&
        type !== 'singleCheckbox' &&
        type !== 'quantityController' &&
        label && (
          <InputLabel
            type={type}
            invalid={invalid}
            label={label}
            tooltipContent={tooltipContent}
            id={id}
            isLabelHtml={isLabelHtml}
            disabled={disabled ?? false}
          />
        )}
      {description && <StyledDescription id={getDescriptionID(field.name)}>{description}</StyledDescription>}
      {renderInput()}
      {requirements && <FieldRequirements value={field.value} requirements={requirements} />}
      {helpText && <StyledFormText id={getHelpTextID(field.name)}>{helpText}</StyledFormText>}
      {type !== 'FNOLAddress' && invalid && error && <FieldFeedback error={error} id={errorId} />}
    </StyledFormGroup>
  );
}

FormikFormField.defaultProps = {
  options: null,
  label: null,
  isLabelHtml: false,
  helpText: null,
  description: null,
  subText: null,
  requirements: null,
  formGroupProps: null,
  className: null,
  content: null,
  showInitialErrors: false,
};

export default FormikFormField;
