import { string, object, array, number, date, StringSchema } from 'yup';
import moment from 'moment';
import { get } from 'lodash/fp';
import { IntlShape } from 'react-intl';
import { getEmailSchema } from '@whitelabel/helpers/forms/schema';
import { ReactStateSetter } from '@whitelabel/helpers/types';
import messages from '@whitelabel/helpers/forms/messages';
import { FORM_INPUT_TYPES, ZENDESK_FILE_LIMIT } from '@whitelabel/helpers/constants';
import { REGEX_INS } from '@whitelabel/xcover-shared/helpers/constants';
import { formatBirthDateToAPI } from '@whitelabel/xcover-shared/helpers/utils';
import {
  getLoggedInContactReasons,
  getNotLoggedInContactReasons,
} from '@whitelabel/xcover-shared/helpers/messages/contactReasons';
import { isStringSchema, isNumberSchema, isArraySchema } from '../types/yupTypes';

function allowEmptyString(value: any, originalValue: any) {
  // @ts-ignore ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  if (this.isType(value)) return value;
  if (!originalValue || !originalValue.trim()) return undefined;
  return value;
}

export const getDobSchema = (intl: IntlShape, required = true) => {
  const maxAge = 125;
  const schema = object({
    dobDay: string(),
    dobMonth: string(),
    dobYear: string(),
  })
    .test('date-valid', intl.formatMessage(messages.dobMaxAge), (value) => {
      if (value) {
        const dob = formatBirthDateToAPI(value);
        return !dob || moment(dob, 'YYYY-MM-DD', true).isValid();
      }
      return true;
    })
    .test('dobMaxAge', intl.formatMessage(messages.dobMaxAge), (value) => {
      if (value) {
        const dob = formatBirthDateToAPI(value);
        // @ts-ignore
        return moment().diff(moment(dob), 'years') <= maxAge && moment().diff(moment(dob), 'days') >= 1;
      }
      return true;
    });

  if (!required) {
    return schema.notRequired().default(undefined);
  }
  return schema;
};

// 'country_select' errors when using proper InputType, doesn't exist in that list, add?
export const getQuoteFormSchemaType = (/* type: InputType */ type: any, intl: IntlShape) => {
  switch (type) {
    case 'number':
    case 'percentage':
      return number().transform(allowEmptyString);
    case 'datepicker':
      return date().transform(allowEmptyString);
    case 'country_select':
      return string();
    case 'checkbox':
      return array();

    case FORM_INPUT_TYPES.ADDRESS:
      return object({
        region: string().required(intl.formatMessage(messages.regionRequired)),
        postcode: string().required(intl.formatMessage(messages.postcodeRequired)),
      });
    default:
      return string().trim();
  }
};

// Returns Yup schema with field validation rules based FNOL field and quote to be claimed against
export const getSchemaValidation = (schema: any, field: any) => {
  const { type, validation, required } = field;
  const integer = type === 'numeric' && !get('float', validation);
  const min =
    get('length.min', validation) ?? get('numeric.min', validation) ?? get('multiselect.min', validation) ?? undefined;
  const max = get('length.max', validation) || get('numeric.max', validation) || get('multiselect.max', validation);
  let validatedSchema = schema;

  if (required) {
    // File upload fields need min 1 validation for required validation to work
    if (type === 'file') validatedSchema = validatedSchema.min(1);
    validatedSchema = validatedSchema.required();
  }

  if (integer) validatedSchema = validatedSchema.integer();
  if (min !== undefined) validatedSchema = validatedSchema.min(min);
  if (max) validatedSchema = validatedSchema.max(max);

  return validatedSchema;
};

export const getQuestionSchema = (
  { name, input, validation: { min, max, required, regex } }: any,
  intl: IntlShape,
  setGlobalError: ReactStateSetter<string>,
  validCountry = 'AU',
) => {
  const locationErrorMessage = intl.formatMessage(messages.isSupportedLocation);
  // TODO: This is passing `input`
  // But `getQuoteFormSchemaType` calls it `type` inside.
  // KW questionnaire has both, `input` only needed for `address` to work.
  // However this sets the empty error message to `Region is required`
  let schema = getQuoteFormSchemaType(input, intl).label(name);

  if (required) {
    schema = schema.required();
  }

  if (isStringSchema(schema)) {
    if (input === 'email') {
      schema = schema.email(intl.formatMessage(messages.isEmail));
    }
    if (regex) {
      schema = schema.matches(regex, intl.formatMessage(messages.invalidValue));
    }
  }

  if (name === 'post_code') {
    // @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 3.
    schema = schema.test('postcode', locationErrorMessage, (value: any) => {
      if (value !== undefined) {
        if (!value.match(regex) || (min && value.length < min) || (max && value.length > max)) {
          setGlobalError(locationErrorMessage);
          return false;
        }
      }
      setGlobalError('');
      return true;
    });
  } else if (name === 'country') {
    // @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 3.
    schema = schema.test('country', locationErrorMessage, (value: any) => {
      if (value !== validCountry) {
        setGlobalError(locationErrorMessage);
        return false;
      }
      setGlobalError('');
      return true;
    });
  } else if (input === FORM_INPUT_TYPES.ADDRESS) {
    // @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 3.
    schema = schema.test(name, locationErrorMessage, (value: any) => {
      const isValidCountry = value.country === validCountry;
      if (!isValidCountry) {
        setGlobalError(locationErrorMessage);
        return false;
      }
      setGlobalError('');
      return true;
    });
  } else if (isNumberSchema(schema) || isStringSchema(schema) || isArraySchema(schema)) {
    if (min) schema = schema.min(min);
    if (max) schema = schema.max(max);
  }

  return schema;
};

export const getContactFormSchema = (
  formatMessage: IntlShape['formatMessage'],
  isLoggedIn: boolean,
  showContactReason = true,
) => {
  const contactReasons = isLoggedIn
    ? getLoggedInContactReasons({ formatMessage })
    : getNotLoggedInContactReasons(formatMessage);
  // Request will be rejected if this regex in the zendesk filed is not met
  // const REFERENCE_NUMBER_REGEX = /(.){4,5}-(.){4,5}-INS/;
  const baseFields = {
    fullName: string().trim().required(),
    email: getEmailSchema(),
    refNumber: isLoggedIn
      ? string().required().matches(REGEX_INS, formatMessage(messages.refNumberInvalidMessage))
      : string().matches(REGEX_INS, formatMessage(messages.refNumberInvalidMessage)),
    enquiry: string().trim().required(),
    files: array().max(ZENDESK_FILE_LIMIT, formatMessage(messages.maxFiles)),
  };
  if (showContactReason) {
    return object({
      ...baseFields,
      contactReasonLevelOne: string().required(),
      contactReasonLevelTwo: string().when('contactReasonLevelOne', {
        is: (option: string) => contactReasons.find(({ value }) => value === option)?.subOptions,
        then: (schema: StringSchema) => schema.required(),
      }),
    });
  }

  return object(baseFields);
};
